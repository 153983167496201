module.exports = [{
      plugin: require('../node_modules/gatsby-remark-lottie/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/ernestasandriuskevicius/Desktop/WEBDEV/SOCIAL/shiboon/Shiboon_landing/src/intl","languages":["en","de","it","jp","es"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"shiboon-landing-page","short_name":"Shiboon","start_url":"/","background_color":"rgb(3 9 22)","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"480518b12c8b8134242e09f5878fe350"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
